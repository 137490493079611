import React from 'react'
import { SkipNavLink } from '@reach/skip-nav'
import { StaticImage } from 'gatsby-plugin-image'

class Header extends React.Component {
  render() {
    return (
      <section id="header">
        <SkipNavLink />
        <header id="navigation">
          <StaticImage
            alt="sketchnote laurie"
            src="../data/headers/illustration.png"
            className="avatar"
            layout="fixed"
            width={170}
            objectFit="contain"
            placeholder="none"
          />
          <h1 className="hs h1">
            <a className="a" href="/">
              LAURIE BARTH
            </a>
          </h1>
          <nav>
            <a className="a" href="/speaking/">
              Speaking
            </a>
            <a className="a" href="/blog/">
              Blog
            </a>
            <a className="a" href="/lessons/">
              Lessons
            </a>
            <a className="a" href="/podcasts/">
              Podcasts
            </a>
            <a className="a" href="/projects/">
              Projects
            </a>
            <a className="a" href="/contact/">
              Contact
            </a>
          </nav>
        </header>
      </section>
    )
  }
}

export default Header
