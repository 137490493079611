import React from 'react'

import Header from './Header'
import Footer from './Footer'
import Helmet from 'react-helmet'
import { SkipNavContent } from "@reach/skip-nav"

import '../assets/css/test.css'
import "@reach/skip-nav/styles.css";


class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: 'is-loading',
    }
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 100)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render() {
    const { children } = this.props
    const siteTitle = 'LAURIE ON TECH'

    return (
      <div className={`body ${this.state.loading}`}>
        <Helmet title={siteTitle}>
          <meta charSet="utf-8" />
          <meta
            name="Description"
            content="A personal site for Laurie Barth aka laurieontech."
          />
          <meta
            name="monetization"
            content="$ilp.uphold.com/Gwb8akh89hFn">
            </meta>
          <title>{siteTitle}</title>
          <html lang="en" />
          <link rel="canonical" href="https://laurieontech.com/" />
        </Helmet>
        <Header />
        <SkipNavContent />
        <main>
        {children}
        </main>
        <Footer />
      </div>
    )
  }
}

export default Template
